import { render, staticRenderFns } from "./HomeIndustry.vue?vue&type=template&id=49f61afc&scoped=true&"
import script from "./HomeIndustry.vue?vue&type=script&lang=js&"
export * from "./HomeIndustry.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "49f61afc",
  null
  
)

export default component.exports